import React,{useEffect} from 'react';
import ChartistGraph from 'react-chartist' 
import "./material-dashboard-react.css"
import 'chartjs-plugin-datalabels';

import * as ChartistTooltips from 'chartist-plugin-tooltips-updated'

import {Chart ,Bar} from 'react-chartjs-2'

var Chartist = require("chartist");
Chart.defaults.global.legend.display = false;


var delays = 80,
  durations = 500;
var delays2 = 80,
  durations2 = 500;

const MixedChart = ({data,height}) => {
    console.log(data)
    let colors=['#f44336','#ff9800','#4caf50','#00bcd4','#9c27b0','#00bbff']
    

    // const getColors=()=>{
    //   let bgColor=[]
    //   for (let i = 0; i < data.series[0].length; i++) {
    //     bgColor.push(colors[i % colors.length]);  
    //   }
    //   return bgColor
    // }

    // useEffect(()=>{
    //   console.log(getColors())
    // },[])


    // const temData={
    //   labels:data.labels,
    //   datasets:[
    //     {
    //       label:null,
    //       data:data.series[0],
    //       backgroundColor:getColors()
    //     //   backgroundColor: [
    //     //     // '#f44336',
    //     //     // '#ff9800',
    //     //     // '#4caf50',
    //     //     // '#00bcd4',
    //     //     // '#9c27b0',
    //     //     // '#00bbff'
    //     //     'rgba(255, 99, 132, 0.2)',
    //     //         'rgba(54, 162, 235, 0.2)',
    //     //         'rgba(255, 206, 86, 0.2)',
    //     //         'rgba(75, 192, 192, 0.2)',
    //     //         'rgba(153, 102, 255, 0.2)',
    //     //         'rgba(255, 159, 64, 0.2)'
    //     // ],
    //     // borderColor: [
    //     //     'rgba(255, 99, 132, 1)',
    //     //     'rgba(54, 162, 235, 1)',
    //     //     'rgba(255, 206, 86, 1)',
    //     //     'rgba(75, 192, 192, 1)',
    //     //     'rgba(153, 102, 255, 1)',
    //     //     'rgba(255, 159, 64, 1)'
    //     // ],
    //     // borderWidth: 1
    //     }
    //   ]
    // }

    const emailsSubscriptionChart = {
        data: {
          labels: [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "Mai",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec"
          ],
          series: [[542, 443, 320, 780, 553, 453, 326, 434, 568, 610, 756, 895]]
        },
        options: {
          axisX: {
            showGrid: false
          },
          low: 0,
          fullWidth:true,
          plugins: [Chartist.plugins.tooltip()],
          // high: 300,
          chartPadding: {
            top: 20,
            right: 5,
            bottom: 0,
            left: 0
          }
        },
        responsiveOptions: [
          [
            "screen and (max-width: 640px)",
            {
              seriesBarDistance: 5,
              axisX: {
                labelInterpolationFnc: function(value) {
                  return value[0];
                }
              }
            }
          ]
        ],
        animation: {
          draw: function(data) {
            if (data.type === "bar") {
              data.element.animate({
                opacity: {
                  begin: (data.index + 1) * delays2,
                  dur: durations2,
                  from: 0,
                  to: 1,
                  easing: "ease"
                }
              });
            }
          }
        }
    };

     
    
    return (
        <div >
            <Bar data={data}
              height={300} 
              options={{
              responsive:true,
              plugins: {
                datalabels: {
                   display: true,
                   color: 'white'
                }
              },
              maintainAspectRatio:false,
              scales: {
                yAxes: [{
                  ticks: {
                    beginAtZero: true
                  }
                }],
                xAxes: [ { ticks: { autoSkip: false }, barPercentage: 0.4 } ],
              }

            }}/>
            {/* <ChartistGraph
                className="ct-chart"
                data={data||emailsSubscriptionChart.data}
                type="Bar"
                options={emailsSubscriptionChart.options}
                responsiveOptions={emailsSubscriptionChart.responsiveOptions}
                listener={emailsSubscriptionChart.animation}
            /> */}
        </div>
    );
}

export default MixedChart;