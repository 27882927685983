import React, { memo } from "react";
import { TextField, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
//onChange for handling input change
//value for displaying current value

const useStyles = makeStyles((theme) => ({
  bg: {
    background: "pink",
  },
}));

const Input = ({
  error,
  type,
  fullWidth,
  style,
  multiline,
  endAdornment,
  ...rest
}) => {
  const classes = useStyles();
  return (
    <div>
      {console.log("Input rendered")}
      <div>
        <TextField
          type={type || "value"}
          autoComplete="off"
          margin="normal"
          fullWidth={false || fullWidth}
          multiline={false || multiline}
          rows="5"
          {...rest}
        />
      </div>
      {error && <Typography className={classes.bg}>{error}</Typography>}
    </div>
  );
};

export default memo(Input);

// const Input = ({ label, name, error, ...rest }) => {
//   return (
//     <div className="form-group">
//       <label htmlFor={name}>{label}</label>
//       <TextField
//                 disabled={disabled}
//                 ref={ref}
//                 id={name}
//                 InputProps={inputProps}
//                 InputLabelProps={inputLabelProps}
//                 onClick={onClick}
//                 // inputProps={inputProps}
//                 type={type||'value'}
//                 style={style}
//                 label={label}
//                 placeholder={placeholder}
//                 className={className}
//                 margin="normal"
//                 helperText={helperText}
//                 fullWidth={false || fullWidth}
//                 multiline={false||multiline}
//                 variant={variant}
//                 rows="5"
//                 onKeyDown={onKeyDown}
//                 onChange={onChange}
//                 value={value}
//             />
//       {/* <input {...rest} id={name} name={name} className="form-control" /> */}
//       {error && <p className="alert alert-danger">{error}</p>}
//     </div>
//   );
// };

// export default Input;
