import { createMuiTheme } from "@material-ui/core/styles";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#2196f3",
      light: "#ffffff",
    },
    secondary: {
      main: "#f50057",
      light: "#E0E0E0",
    },
  },
  spacing: 5,
  shape: {
    borderRadius: 5,
  },
  props: {
    MuiButtonBase: {
      disableRipple: true,
    },
  },
});

export default theme;
