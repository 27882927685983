import React, { useState } from "react";
import {
  FormControl,
  InputLabel,
  Select,
  OutlinedInput,
  MenuItem,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  formControl: {
    margin: theme.spacing(2, 0, 4, 0),
    minWidth: "100%",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  Dropdown:{
    height:40
  }
}));

const Dropdown = ({
  id,
  name,
  label,
  fullWidth,
  selectedOption,
  dense,
  style,
  selected,
  options,
  placeholder,
  onChange,
  disable,
}) => {
  const [value, setValue] = useState(selectedOption || "");
  const inputLabel = React.useRef(null);
  const [labelWidth, setLabelWidth] = React.useState(0);
  React.useEffect(() => {
    setLabelWidth(inputLabel.current.offsetWidth);
  }, []);

  // const handleChange=(event)=>{
  //   console.log(event.target)
  // }

  // const handleChange = event => {
  // 	setValue(event.target.value);
  // 	onChange({
  // 		target:{
  // 			id: id,
  // 			value: event.target.value
  // 		}
  // 	})
  // }

  const classes = useStyles();
  return (
    <div>
      {console.log("Render")}
      <FormControl variant="outlined" className={classes.formControl}>
        <InputLabel
          margin={dense ? "dense" : null}
          style={style}
          ref={inputLabel}
        >
          {label}
        </InputLabel>
        <Select
          // className={classes.Dropdown}
          name={name}
          margin="none"
          fullWidth={fullWidth || false}
          disabled={disable || false}
          style={style || { textTransform: "capitalize" }}
          value={value || selected}
          onChange={onChange}
          input={<OutlinedInput labelWidth={labelWidth} />}
        >
          {options.map((option, index) => {
            return (
              <MenuItem
                key={index}
                style={style || { textTransform: "capitalize" }}
                value={option}
              >
                {option}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </div>
  );
};

export default Dropdown;
// import React from "react";

// const DropDown = ({ name, label, options, onChange }) => {
//   return (
//     <div className="form-group">
//       <label for={name}>{label}</label>
//       <select
//         className="form-control"
//         name={name}
//         id={name}
//         onChange={onChange}
//       >
//         {options.map((item, index) => (
//           <option key={item + index} value={item}>
//             {item}
//           </option>
//         ))}
//       </select>
//     </div>
//   );
// };

// export default DropDown;
