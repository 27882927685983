import React,{useEffect, useState} from 'react';
import {Typography,makeStyles,Grid} from '@material-ui/core'
import clsx from 'clsx'
import CsvDownload from 'react-json-to-csv'
import domtoimage from 'dom-to-image';
import html2canvas from 'html2canvas'

import AdvanceTable from '../../components/table/AdvancedTable'
import Layout2 from '../../components/layoutsForDashboard/Layout2'
import TopLayer from '../../components/layout/layout'
import CardWithIcon from '../../components/cardWithIcon/CardWithIcon'
import Dropdown from '../../components/dropdown/Dropdown'
import BarCharts from '../../components/Charts/BarChart'
import ButtonComponent from '../../components/button/Button'
import Table from '../../components/table/Table'

import {MdContentCopy} from 'react-icons/md'
import {GiPerson} from 'react-icons/gi'
import {BsFillPersonFill} from 'react-icons/bs'
import Warning from "@material-ui/icons/Warning";
import axios from 'axios'
import jsPDF from 'jspdf'

import autoTable from 'jspdf-autotable'

 



const useStyles = makeStyles((theme) => ({
    container:{
        borderRadius:theme.spacing(2),
        border:"1px solid lightgrey",
        padding:theme.spacing(0,0,0,0),
        margin:theme.spacing(0,0,10,0)
    },
    heading:{
        padding:theme.spacing(2,3,2,3),
        borderTopRightRadius:theme.spacing(2),
        borderTopLeftRadius:theme.spacing(2),
        marginBottom:theme.spacing(3),
        color:'white' 
    },
    heading1:{
        padding:theme.spacing(0,1,0,3),
        borderTopRightRadius:theme.spacing(2),
        borderTopLeftRadius:theme.spacing(2),
        marginBottom:theme.spacing(3),
        color:'white' 
    },
    dropdownContainer:{
        padding:theme.spacing(0, 3, 0, 3),
        width:'100%'
    },
    dropdownContainer1:{
        padding:theme.spacing(3),
        width:'40%'
    },
    grid:{
        padding:theme.spacing(3)
    },
    paddAr:{
        padding:theme.spacing(0,3,3,3)
    },
    paddB:{
        paddingBottom:theme.spacing(3)
    }
 }));
 


const DashboardReports = () => {

    const classes=useStyles()
    const [city,setCity]=useState(null)
    const [cities, setCities] = useState(null)
    const [mysteryType ,setMysteryType]=useState(null)
    const [mysteryTypes ,setMysteryTypes]=useState(null)
    const [location,setLocation]=useState(null)
    const [locations, setLocations] = useState(null)
    const [mysteryDate,setMysteryDate]=useState(null)
    const [mysteryDates,setMysteryDates]=useState(null)
    const [questionnaire,setQuestionnaire]=useState(null)
    const [visitInfo,setVisitInfo]=useState(null)
    const [graphValues,setGraphValues]=useState(null)
    const [csvData,setCsvData]=useState(null)
    const [img,setImg]=useState()

    useEffect(() => {
        getCities()
    }, [])

    const getCities = () => {
        fetch(`http://espyv2.markematics.net/EspyV2.svc/getCities`)
        .then(response => response.json())
        .then(responseJson => JSON.parse(responseJson))
        .then(data => setCities(data.Cities))
    }

    const setNullValues = () => {
        setQuestionnaire(null)
        setVisitInfo(null)
        setGraphValues(null)
    }

    const onCityChange = (event)=> {
        const {value} = event.target
        setNullValues()
        setMysteryType(null)
        setMysteryTypes(null)
        setLocation(null)
        setLocations(null)
        setMysteryDate(null) 
        setMysteryDates(null) 
        setCity(value)
        getMysteryTypes(value)
    }

    const getMysteryTypes = (tempCity) => {
        fetch(`http://espyv2.markematics.net/EspyV2.svc/getMysteryType?city=${tempCity}`)
        .then(response => response.json())
        .then(responseJson => JSON.parse(responseJson))
        .then(data => setMysteryTypes(data.mysteryType))
    }

    const onMystryTypeChange = (event)=> {
        const {value} = event.target
        setNullValues()
        setLocation(null)
        setLocations(null)
        setMysteryDate(null) 
        setMysteryDates(null)
        setMysteryType(value)
        getStoreLocation(city, value)
    }

    const getStoreLocation = (tempCity, tempMysteryType) => {
        fetch(`http://espyv2.markematics.net/EspyV2.svc/getStoreLocation?city=${tempCity}&mysteryType=${tempMysteryType}`)
        .then(response => response.json())
        .then(responseJson => JSON.parse(responseJson))
        .then(data => setLocations(data.storeLocations))
    }

    const onLocationChange = (event)=> {
        const {value} = event.target
        setNullValues()
        setMysteryDate(null) 
        setMysteryDates(null)
        setLocation(value)
        getMysteryDates(city, mysteryType, value)
    } 

    const getMysteryDates = (tempCity, tempMysteryType, tempLocation) => {
        fetch(`http://espyv2.markematics.net/EspyV2.svc/getVisitDates?city=${tempCity}&mysteryType=${tempMysteryType}&storeLocation=${tempLocation}`)
        .then(response => response.json())
        .then(responseJson => JSON.parse(responseJson))
        .then(data => setMysteryDates(data.visitDates))
    }

    const onMysteryDateChange = (event)=> {
        const {value} = event.target
        setMysteryDate(value)
    }

    const getReport = (tempCity, tempMysteryType, tempLocation, tempMysteryDate) => {
        fetch(`http://espyv2.markematics.net/EspyV2.svc/getReport?city=${tempCity}&mysteryType=${tempMysteryType}&storeLocation=${tempLocation}&visitDate=${tempMysteryDate}`)
        .then(response => response.json())
        .then(responseJson => JSON.parse(responseJson))
        .then(data => {
            console.log(data)
            setQuestionnaire(getTableData(data.Questionnaire))
            let achievedMarks=data.TotalScore[0]['Acieved Marks']
            let temp={achievedMarks,...data.VisitInformation[0]}
            setVisitInfo(temp)
            setGraphValues(getGraphValues(data.GrphValues))
        })
    }

    const getTableData = questionnaireData => {
        const csvdata = [...questionnaireData.map(item =>  {
            const obj = {...item};
            delete obj.tableData;
            delete obj.ResponseID;
            delete obj.CSSComment;
            delete obj.ID;
            return obj;
        })]
        // console.log(csvdata)
        setCsvData(csvdata)
        const data1 = {}
        data1['columns'] = [
            { title: 'S.no', field: 'SNo' },
            { title: 'Component', field: 'Components' },
            { title: 'Particular', field: 'Particulars'},
            { title: 'Response', field: 'Response'},
            { title: 'Available Marks', field: 'availableMarks',type:"numeric"},
            { title: 'Achieved Marks', field: 'achievedMarks',type:"numeric"},
            { title: 'Comments', field: 'comments'},
          ]
        
        data1['data'] = questionnaireData.map(item => ({
            SNo: item['SNo'],
            Components: item['Components'],
            Particulars: item['Particulars'],
            Response: item['Response'],
            availableMarks: item['Available Marks'],
            achievedMarks: item['Acieved Marks'],
            // CSSComment: item['CSSComment'],
            comments: item['Comment'],
            // ID: item['ID'],
            // ResponseID: item['ResponseID'],
        }))

        // console.log(data1)
        return data1;
    }

    const getGraphValues = (graphVals) => {
        let labels=[]
        let values=[]

        graphVals.map(item=>{
            labels.push(item.Trait)
            values.push(item.Percentage)
        })
        
        let data={
            labels,
            series:[values]
        }
        return data;
    } 

    const mysteryTypeOptions=[
        'A',
        'B',
        'C'
    ]

    const mysteryDateOptions=[
        'A1',
        'B1',
        'C1'
    ]

    const locationOptions=[
        'A2',
        'B2',
        'C2'
    ]

    const handleClick=()=>{
        console.log(city,location,mysteryType,mysteryDate)
        getReport(city, mysteryType, location, mysteryDate);
    }

    const blobToBase64 = blob => {
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        return new Promise(resolve => {
          reader.onloadend = () => {
              console.log(reader.result)
            resolve(reader.result);
            setImg(reader.result)
          };
        });
      };

    const savePDF=()=>{
        let doc = new jsPDF()          

        domtoimage.toBlob(document.getElementById('information'))
        .then(async function (blob) {
            const res=await blobToBase64(blob)
            doc.text(`${location}-${mysteryDates}`,100,10,'center')
            doc.addImage(res,'PNG',10,50,190,70)
            // doc.addPage()
            domtoimage.toBlob(document.getElementById('chart'))
            .then(async function (blob) {
               const res=await blobToBase64(blob)
               doc.addImage(res,'PNG',10,150,190,90)
               doc.addPage()
               let tempQuestionnaire={...questionnaire}
                let head,body
                head=tempQuestionnaire.columns.map(item=>{
                    return(
                        item.title
                    )
                })
    
                body=tempQuestionnaire.data.map(item=>{
                    return Object.values(item)
                })
    
                
                autoTable(doc, {
                    head: [head],
                    body: body,
                })
                doc.save(`${location}-${mysteryDate}-report.pdf`)
                
                
                tempQuestionnaire=null
                body=null
                head=null
            })
            .catch(err=>{
                console.log(err)
            })
        })
        .catch(err=>{
            console.log(err)
        })
       
        

        
    }

    const saveGraph=()=>{
        // html2canvas(document.getElementById('abc')).then(img=>{
        //     console.log(img.toDataURL("image/png"))
        //     window.saveAs(img.toDataURL("image/png"),'img.png')
        //     // domtoimage.toBlob(img.toDataURL())
        //     // .then(function (blob) {
        //     //     window.saveAs(blob,'chart.png')
        //     // });
        // })
    
    domtoimage.toBlob(document.getElementById('chart'))
    .then(function (blob) {
        console.log(blob) 
        window.saveAs(blob,'chart.png')
    })
    .catch(err=>{
        console.log(err)
    })

}

    return (
        <TopLayer>
            <div className={classes.container}>
                <Typography style={{background:'linear-gradient(60deg, #FFA726, #FB8C00)'}} className={classes.heading} variant="h5">Filters</Typography>
                
                <Grid container>
                    <Grid container md={6} xs={12}>
                        {cities && <span className={classes.dropdownContainer}>
                            <Dropdown id="city" name="city" options={cities} selected={city} placeholder="Ciy" label="City" onChange={onCityChange}/>
                        </span>}
                    </Grid>
                    <Grid container md={6} xs={12}>
                        {
                            city && mysteryTypes &&
                            <span className={classes.dropdownContainer}>
                                <Dropdown id="mysteryType" name="mysteryType" options={mysteryTypes} selected={mysteryType} placeholder="Mystery Type" label="Mystery Type" onChange={onMystryTypeChange}/>
                            </span>
                        }
                        
                    </Grid>
                    <Grid container md={8} xs={12}>
                        {
                            city && mysteryType && locations &&
                            <span className={classes.dropdownContainer}>
                                <Dropdown id="location" name="location" options={locations} selected={location} placeholder="Location" label="Location" onChange={onLocationChange}/>
                            </span>
                        }
                    </Grid>
                    {/* <Grid container md={4} xs={12}/> */}
                    <Grid container md={4} xs={12}>
                        {
                            city && mysteryType && location && mysteryDates &&
                            <span className={classes.dropdownContainer}>
                                <Dropdown id="mysteryDate" name="mysteryDate" options={mysteryDates} selected={mysteryDate} placeholder="Visit Date" label="Visit Date" onChange={onMysteryDateChange}/>
                            </span>
                        }
                    </Grid>
                    <Grid container alignItems="center" md={5} xs={12} className={classes.dropdownContainer}>
                        {
                            city && mysteryType && location && mysteryDate &&
                            <ButtonComponent color="primary" variant="contained" onClick={handleClick}>Submit</ButtonComponent>
                        }
                    </Grid>
                </Grid>
            </div>


            { graphValues && visitInfo && 
            <Grid container justify="space-evenly">
                <Grid id="chart" container xs={12} sm={12} style={{height:'100%'}} className={clsx(classes.container,classes.paddingR)}>
                    <Grid container className={classes.heading} style={{width:'100%',background:'linear-gradient(60deg, #26C6DA, #00ACC1)'}}>
                        <Grid container alignItems="center" xs={6}>
                            <Typography  variant="h5">Graph</Typography>
                        </Grid>
                        <Grid container justify="flex-end" alignItems="center" xs={6}>
                            {/* <Typography style={{color:'white',cursor:'pointer'}} onClick={saveGraph}>Save as image</Typography> */}
                        </Grid>
                    </Grid>
                    <div  style={{width:'100%'}}>
                        <Grid container justify="flex-end">
                            {/* <ButtonComponent style={{marginRight:'5px'}} variant="contained" color="primary" onClick={saveGraph}>Save as image</ButtonComponent> */}
                        </Grid>
                        <div className={classes.dropdownContainer1}/>
                         <div id="chart" style={{height:'100%',width:'100%'}}>
                            <BarCharts data={graphValues}/>
                        </div>
                    </div>
                </Grid>   
                <Grid container sm={1}/>   
                <Grid id="information" container xs={12} sm={12} className={clsx(classes.container,classes.paddingL)}>
                    <Typography style={{width:'100%',background:'linear-gradient(60deg, #AB47BC, #8E24AA)'}} className={classes.heading} variant="h5">Information<br/></Typography>
                    <div style={{width:'100%'}}>  
                        <div className={classes.dropdownContainer1}/>
                        <div style={{height:'100%',width:'100%'}}>
                            <Grid container className={classes.paddAr}>
                                <Grid container className={classes.paddB}>
                                    <Grid container direction="column" xs={4}>
                                        <Typography variant="body1"><b>Shopper</b></Typography>
                                        <Typography>{visitInfo.MysteryShopperName}</Typography>
                                    </Grid>
                                    <Grid container direction="column" xs={4}>
                                        <Typography variant="body1"><b>Time In</b></Typography>
                                        <Typography>{visitInfo.TimeIn}</Typography>
                                    </Grid>
                                    <Grid container direction="column" xs={4}>
                                        <Typography variant="body1"><b>Time Out</b></Typography>
                                        <Typography>{visitInfo.TimeOut}</Typography>
                                    </Grid>
                                </Grid>
                                <Grid container className={classes.paddB}>
                                    <Grid container direction="column" xs={4}>
                                        <Typography variant="body1"><b>Education</b></Typography>
                                        <Typography>{visitInfo.mysteryShopperEdu}</Typography>
                                    </Grid>
                                    <Grid container direction="column" xs={4}>
                                        <Typography variant="body1"><b>Mystery Type</b></Typography>
                                        <Typography>{visitInfo.mystryType}</Typography>
                                    </Grid>
                                    <Grid container direction="column" xs={4}>
                                        <Typography variant="body1"><b>Total Score</b></Typography>
                                        <Typography>{visitInfo.achievedMarks}</Typography>
                                    </Grid>
                                </Grid>
                                <Grid container className={classes.paddB} direction="column" xs={12}>
                                    <Typography>{visitInfo.Commentory}</Typography>
                                </Grid>
                            </Grid>
                        </div>
                    </div>
                </Grid>      
            </Grid>
        }
            
            { csvData && questionnaire &&
            <div className={classes.container}>
                <Typography style={{background:'linear-gradient(60deg, #66BB6A, #43A047)'}} className={classes.heading} variant="h5">Report</Typography>
                <div style={{height:'100%',width:'100%'}}>
                    {/* <Table data={data}/> */}
                    <Grid container justify="flex-end">
                    <CsvDownload 
                      data={csvData}
                      filename={`report.csv`}
                      style={{ //pass other props, like styles
                        boxShadow:"0px",
                        background:"transparent",
                        backgroundColor:"0px",
                        borderRadius:"0px",
                        border:"0px solid #a511c0",
                        display:"inline-block",
                        cursor:"pointer","color":"#ffffff",
                        fontSize:"15px",
                        fontWeight:"bold",
                        padding:"0px",
                        textDecoration:"none",
                        textShadow:"0px 1px 0px #9b14b3"
                        }}
                    >
                      <ButtonComponent style={{marginRight:'5px'}} variant="contained" color="primary">Download CSV</ButtonComponent>
                    </CsvDownload>
                    <ButtonComponent style={{marginRight:'5px'}} variant="contained" color="primary" onClick={savePDF}>Save PDF</ButtonComponent>
                    </Grid>
                    
                    <AdvanceTable data={questionnaire}/>
                </div>
            </div>}

        </TopLayer>
    );
}

export default DashboardReports;



/*
Acieved Marks: 1
Available Marks: 1
CSSComment: ""
Comment: ""
Components: "Outlet Exterior"
ID: 25052
Particulars: "Was the signage of outlet visible (clearly identifies the presence of the outlet) and clean?"
Response: "Yes"
ResponseID: 3328
SNo: 1

*/

    // const data1={
    //     columns: [
    //         { title: 'S.no', field: 'SNo' },
    //         { title: 'Component', field: 'Components' },
    //         { title: 'Particular', field: 'Particulars'},
    //         { title: 'Response', field: 'Response'},
    //         { title: 'Available Marks', field: 'availableMarks',type:"numeric"},
    //         { title: 'Achieved Marks', field: 'achievedMarks',type:"numeric"},
    //         { title: 'Comments', field: 'Comment'},
    //       ],
    //       data: [
    //         {
    //             sno:'1',
    //             component:'ABC1',
    //             particular:'testing1',
    //             response:'1231',
    //             availableMarks:'1231',
    //             achievedMarks:'1231',
    //             comments:'hello1'
    //         },
    //         {
    //             sno:'2',
    //             component:'ABC2',
    //             particular:'testing2',
    //             response:'1232',
    //             availableMarks:'1232',
    //             achievedMarks:'1232',
    //             comments:'hello2'
    //         },
    //         {
    //             sno:'3',
    //             component:'ABC3',
    //             particular:'testing3',
    //             response:'1233',
    //             availableMarks:'1233',
    //             achievedMarks:'1233',
    //             comments:'hello3'
    //         },
    //         {
    //             sno:'4',
    //             component:'ABC4',
    //             particular:'testing4',
    //             response:'1234',
    //             availableMarks:'1234',
    //             achievedMarks:'1234',
    //             comments:'hello4'
    //         },
    //         {
    //             sno:'5',
    //             component:'ABC5',
    //             particular:'testing5',
    //             response:'1235',
    //             availableMarks:'1235',
    //             achievedMarks:'1235',
    //             comments:'hello5'
    //         },
    //         {
    //             sno:'6',
    //             component:'ABC6',
    //             particular:'testing6',
    //             response:'1236',
    //             availableMarks:'1236',
    //             achievedMarks:'1236',
    //             comments:'hello6'
    //         },
    //         {
    //             sno:'7',
    //             component:'ABC7',
    //             particular:'testing7',
    //             response:'1237',
    //             availableMarks:'1237',
    //             achievedMarks:'1237',
    //             comments:'hello7'
    //         },
    //       ],
    // }

    // const data={
    //     header:['S.no','Component','Particular','Response','Available Marks','Achieved Marks','Comments'],
    //     rows:[
    //         {
    //             Sno:1,
    //             Component:'ABC',
    //             Particular:'testing',
    //             Response:'123',
    //             AvailableMarks:'123',
    //             AchievedMarks:'123',
    //             Comments:'hello'
    //         },
    //         {
    //             Sno:1,
    //             Component:'ABC',
    //             Particular:'testing',
    //             Response:'123',
    //             AvailableMarks:'123',
    //             AchievedMarks:'123',
    //             Comments:'hello'
    //         },
    //         {
    //             Sno:1,
    //             Component:'ABC',
    //             Particular:'testing',
    //             Response:'123',
    //             AvailableMarks:'123',
    //             AchievedMarks:'123',
    //             Comments:'hello'
    //         },
    //         {
    //             Sno:1,
    //             Component:'ABC',
    //             Particular:'testing',
    //             Response:'123',
    //             AvailableMarks:'123',
    //             AchievedMarks:'123',
    //             Comments:'hello'
    //         },
    //         {
    //             Sno:1,
    //             Component:'ABC',
    //             Particular:'testing',
    //             Response:'123',
    //             AvailableMarks:'123',
    //             AchievedMarks:'123',
    //             Comments:'hello'
    //         },
    //         {
    //             Sno:1,
    //             Component:'ABC',
    //             Particular:'testing',
    //             Response:'123',
    //             AvailableMarks:'123',
    //             AchievedMarks:'123',
    //             Comments:'hello'
    //         },
    //         {
    //             Sno:1,
    //             Component:'ABC',
    //             Particular:'testing',
    //             Response:'123',
    //             AvailableMarks:'123',
    //             AchievedMarks:'123',
    //             Comments:'hello'
    //         },
    //         {
    //             Sno:1,
    //             Component:'ABC',
    //             Particular:'testing',
    //             Response:'123',
    //             AvailableMarks:'123',
    //             AchievedMarks:'123',
    //             Comments:'hello'
    //         },
    //         {
    //             Sno:1,
    //             Component:'ABC',
    //             Particular:'testing',
    //             Response:'123',
    //             AvailableMarks:'123',
    //             AchievedMarks:'123',
    //             Comments:'hello'
    //         },
    //         {
    //             Sno:1,
    //             Component:'ABC',
    //             Particular:'testing',
    //             Response:'123',
    //             AvailableMarks:'123',
    //             AchievedMarks:'123',
    //             Comments:'hello'
    //         },
    //         {
    //             Sno:1,
    //             Component:'ABC',
    //             Particular:'testing',
    //             Response:'123',
    //             AvailableMarks:'123',
    //             AchievedMarks:'123',
    //             Comments:'hello'
    //         },
    //         {
    //             Sno:1,
    //             Component:'ABC',
    //             Particular:'testing',
    //             Response:'123',
    //             AvailableMarks:'123',
    //             AchievedMarks:'123',
    //             Comments:'hello'
    //         },
    //         {
    //             Sno:1,
    //             Component:'ABC',
    //             Particular:'testing',
    //             Response:'123',
    //             AvailableMarks:'123',
    //             AchievedMarks:'123',
    //             Comments:'hello'
    //         },
    //         {
    //             Sno:1,
    //             Component:'ABC',
    //             Particular:'testing',
    //             Response:'123',
    //             AvailableMarks:'123',
    //             AchievedMarks:'123',
    //             Comments:'hello'
    //         },
    //         {
    //             Sno:1,
    //             Component:'ABC',
    //             Particular:'testing',
    //             Response:'123',
    //             AvailableMarks:'123',
    //             AchievedMarks:'123',
    //             Comments:'hello'
    //         },
    //     ],
    //     actions:[]
    // }

    // const cities=[
    //     'Karachi',
    //     'Lahore',
    //     'Islamabad'
    // ]