import React from 'react';
import {Route,Switch} from 'react-router-dom'

import Login from './screens/Login/Login'
import ClientDashboard from './screens/ClientDashboard/ClientDashboard'
import DashboardReports from './screens/DashboardReports/DashboardReports'
import Comparison from './screens/Comparison/Comparison'

import ProtectedRoute from './components/ProtectedRoute/ProtectedRoute'

import TopLayer from './components/layout/layout'

const Router=()=>{
    return(
        <Switch>
            <ProtectedRoute exact path="/" component={ClientDashboard}/>
            <ProtectedRoute path="/login" component={Login}/>
            {/* <Route path="/login" component={Login}/> */}
            <ProtectedRoute path="/DashboardReports" component={DashboardReports}/>
            <ProtectedRoute path="/Comparison" component={Comparison}/>
            {/* <TopLayer> */}
                
            {/* </TopLayer> */}
        </Switch>
    )
}

export default Router