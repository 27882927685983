import React from 'react';
import {Grid} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
   marginTB:{
       margin:theme.spacing(2,0,2,0)
   },
   paddingLR:{
       padding:theme.spacing(0,2,0,2)
   },
   noMargin:{
       margin:theme.spacing(0)
   }
  }));

const Layout2 = ({R1C1,R1C2,R1C3,R2C1,R2C2,R2C3,R2C4,R3C1,R3C2,R4C1,R4C2,margin}) => {
    const classes = useStyles();

    return (
        <Grid container>
            <Grid container className={!margin?classes.noMargin: classes.marginTB}>
                <Grid container className={classes.paddingLR} justify="center" alignItems="center" xs={12} sm={4}>
                    {R1C1}
                </Grid>
                <Grid container justify="center" alignItems="center" xs={12} sm={4}>
                    {R1C2}
                </Grid>
                <Grid container justify="center" alignItems="center" xs={12} sm={4}>
                    {R1C3}
                </Grid>
            </Grid>
            <Grid container className={!margin?classes.noMargin: classes.marginTB}>
                <Grid container className={classes.paddingLR} justify="space-around" alignItems="center" xs={12} sm={3}>
                    {R2C1}
                </Grid>
                <Grid container className={classes.paddingLR} justify="space-around" alignItems="center" xs={12} sm={3}>
                    {R2C2}
                </Grid>
                <Grid container className={classes.paddingLR} justify="space-around" alignItems="center" xs={12} sm={3}>
                    {R2C3}
                </Grid>
                <Grid container className={classes.paddingLR} justify="space-around" alignItems="center" xs={12} sm={3}>
                    {R2C4}
                </Grid>
            </Grid>
            <Grid container className={!margin?classes.noMargin: classes.marginTB}>
                <Grid container justify="center" alignItems="center" xs={12} sm={6}>
                    {R3C1}
                </Grid>
                <Grid container justify="center" alignItems="center" xs={12} sm={6}>
                    {R3C2}
                </Grid>
            </Grid>
            <Grid container className={!margin?classes.noMargin: classes.marginTB}>
                <Grid container justify="center" alignItems="center" xs={12} sm={6}>
                    {R4C1}
                </Grid>
                <Grid container justify="center" alignItems="center" xs={12} sm={6}>
                    {R4C2}
                </Grid>
            </Grid>
            
        </Grid>
    );
}

export default Layout2;