import React,{useEffect,useState,memo} from 'react';
import { makeStyles,withStyles } from '@material-ui/core/styles';
import {Grid} from "@material-ui/core"
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import TablePagination from '@material-ui/core/TablePagination';
import EditIcon from '@material-ui/icons/Edit';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Popup from './popup'


const useStyles = makeStyles({
  table: {
    // minWidth: 650,
  },
  align:{
    verticalAlign:'middle'
  }
});

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const rows = [
  createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
  createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
  createData('Eclair', 262, 16.0, 24, 6.0),
  createData('Cupcake', 305, 3.7, 67, 4.3),
  createData('Gingerbread', 356, 16.0, 49, 3.9),
  createData('Cupcake', 305, 3.7, 67, 4.3),
  createData('Donut', 452, 25.0, 51, 4.9),
  createData('Eclair', 262, 16.0, 24, 6.0),
];


const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    body: {
      fontSize: 14,
    },
  }))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
      },
    },
  }))(TableRow);

function UserTable({data}) {
  const classes = useStyles();
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [page, setPage] = React.useState(0);
  const [init,setInit]=useState(false)
  const [TableRows,setTableRows]=useState()
  const [operation,setOperation]=useState({
    func:null,
    row:null
  })
  const [openDeletePopup,setOpenDeletePopup]=useState(false)
  const [popupAnswer,setPopupAnswer]=useState(null)


  useEffect(()=>{
      if(!init){
        console.log('data',data)
        let temp=data.rows.map((item,index)=>{
            return(
                Object.entries(item)
            )
        })
        setTableRows(temp)
        setInit(true)
      }
      if(popupAnswer){
        operation.func(arrToObject(operation.row))
        setPopupAnswer(null)
      }
  },[popupAnswer])

  
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const arrToObject = (row) => {
    const obj = {}
    row.forEach(item => {
      obj[item[0]] = item[1]
    })
    return obj
  }

  const handleEdit=(func,row)=>{
    setOpenDeletePopup(true)
    setOperation({func,row})
  }

  const handleDelete=(func,row)=>{
    setOpenDeletePopup(true)
    setOperation({func,row})
  }
  

  return (
    <div> 
      {openDeletePopup && <Popup setOpen={setOpenDeletePopup} setAnswer={setPopupAnswer} />}   
    <TableContainer component={Paper}>
      <Table stickyHeader className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
              {data && data.header.map((item,index)=>{
                    return(
                        <StyledTableCell align="left">{item}</StyledTableCell>
                    )
              })}
          </TableRow>
        </TableHead>
        <TableBody>
        {
            TableRows && TableRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((row, index) => {
                return( 
                <StyledTableRow hover>
                    {
                        row.map((item1,index1)=>{
                            return(
                              <>
                                {
                                  item1[0]!=='onClick' && item1[0]!=='onDelete' &&
                                  <TableCell align="left" >
                                    {item1[1]}
                                  </TableCell>
                                }
                              </>
                            )
                        })
                    }
                    {
                      data.actions.length>0 &&
                      <TableCell alignItems="center">
                        {
                          data.actions[0].onEdit &&
                          // <EditIcon onClick={()=>data.actions[0].onEdit(arrToObject(row))}/>
                          <EditIcon onClick={()=>data.actions[0].onEdit(arrToObject(row))}/>
                        }
                        {
                          data.actions[1].onDelete &&
                          // <DeleteOutline onClick={()=>data.actions[1].onDelete(arrToObject(row))}/>
                          <DeleteOutline onClick={()=>handleDelete(data.actions[1].onDelete,row)}/>
                        }
                      </TableCell>
                    }
                                        
                 </StyledTableRow>
                )
            })
        }
        </TableBody>
      </Table>  
    </TableContainer>
    <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </div>
  );
}
export default UserTable