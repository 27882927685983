import React,{useState} from 'react';

import {Grid,Typography,makeStyles} from '@material-ui/core'

import TopLayer from '../../components/layout/layout'
import MixedChart from '../../components/Charts/MixedChart'
import Dropdown from '../../components/dropdown/Dropdown'


const useStyles = makeStyles((theme) => ({
    container:{
        borderRadius:theme.spacing(2),
        border:"1px solid lightgrey",
        padding:theme.spacing(0,0,0,0),
        margin:theme.spacing(0,0,10,0)
    },
    heading:{
        padding:theme.spacing(2,3,2,3),
        borderTopRightRadius:theme.spacing(2),
        borderTopLeftRadius:theme.spacing(2),
        marginBottom:theme.spacing(3),
        color:'white' 
    },
    dropdownContainer:{
        padding:theme.spacing(3),
        width:'20%'
    },
    dropdownContainer1:{
        padding:theme.spacing(3),
        width:'40%'
    },
    grid:{
        padding:theme.spacing(3)
    },
    paddingL:{
     //    marginLeft:theme.spacing(2)
    },
    paddingR:{
     //   marginRight:theme.spacing(2)
    }
 }));
  

const Comparison = () => {
    const [citiesList,setCitiesList]=useState([])
    const [city,setCity]=useState(null)

    let colors=['#f44336','#ff9800','#4caf50','#00bcd4','#9c27b0','#00bbff']
    
    const getColors=()=>{
        let bgColor=[]
        for (let i = 0; i < colors.length; i++) {
          bgColor.push(colors[i % colors.length]);  
        }
        return bgColor
    }
    const classes=useStyles()
    const data=  {
        labels: [
          "January",
          "February",
          "March",
          "April",
          "May",
          "June",
          "July",
          "August",
          "September"
        ],
        datasets: [
          {
            label: "My First dataset",
            backgroundColor: getColors(),
            // backgroundColor: "#f44336",
            // borderColor: "rgba(255,99,132,1)",
            // borderWidth: 1,
            //stack: 1,
            // hoverBackgroundColor: "rgba(255,99,132,0.4)",
            // hoverBorderColor: "rgba(255,99,132,1)",
            data: [65, 59, 80, 81, 56, 55, 40,55, 40]
          },
          {
            label: "My second dataset",
            backgroundColor: getColors(),
            // backgroundColor: "#ff9800",
            // borderColor: "rgba(255,99,132,1)",
            // borderWidth: 1,
            //stack: 1,
            // hoverBackgroundColor: "rgba(255,99,132,0.4)",
            // hoverBorderColor: "rgba(255,99,132,1)",
            data: [45, 79, 50, 41, 16, 85, 20]
          }
        ]
    }

    
    const onChange1=(event)=>{
        let {value}=event.target
        setCity(value)
    }
    
  

    return (
        <TopLayer>
            <div id="chart" className={classes.container}>
                <Typography style={{background:'linear-gradient(60deg, #66BB6A, #43A047)'}} className={classes.heading} variant="h5">Graph City Wise Comparison</Typography>
                <div style={{height:'150%',width:'100%'}}>
                    {/* {graphValues &&<BarCharts data={graphValues}/>} */}
                   <MixedChart data={data}/>
                </div>
            </div>

            <div id="chart" className={classes.container}>
                <Typography style={{background:'linear-gradient(60deg, #EF5350, #E53935)'}} className={classes.heading} variant="h5">Outlet Wise Comparison By City</Typography>
                <div className={classes.dropdownContainer}>
                    <Dropdown id="city"  name="city"  options={citiesList} selected={city} placeholder="City" label="City" onChange={onChange1}/>
                </div>
                <div style={{height:'150%',width:'100%'}}>
                    {/* {graphValues &&<BarCharts data={graphValues}/>} */}
                    <MixedChart data={data}/>
                </div>
            </div>
        </TopLayer>
    );
}

export default Comparison;