import React from 'react';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import {Grid} from '@material-ui/core'
import ButtonComponent from '../button/Button'
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    popup:{
        padding:theme.spacing(5)
    }
  }));

const Popup = ({setOpen,setAnswer}) => {
    const classes = useStyles();

    const handleClose=()=>{
        setOpen(false)
    }

    const handleAnswer=(ans)=>{
        //console.log(ans)
        setAnswer(ans)
        handleClose()
        
    }

    return (
        <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={true}>
            <div className={classes.popup}> 
                <DialogTitle id="simple-dialog-title">Are you sure?</DialogTitle>
                <Grid container>
                    <Grid container xs={6} justify="center">
                        <ButtonComponent color="primary" variant="contained" onClick={()=>handleAnswer(true)}>Yes</ButtonComponent>
                    </Grid>
                    <Grid container xs={6} justify="center">
                        <ButtonComponent color="primary" variant="contained" onClick={()=>handleAnswer(false)}>No</ButtonComponent>
                    </Grid>
                </Grid>
            </div>
        </Dialog>
    );
}

export default Popup;