import React from "react";
import { Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import clsx from 'clsx'

const useStyles = makeStyles(theme => ({
  button:{
      textTransform:"capitalize",
      padding:theme.spacing(1.5,2,1.5,2),
      margin:theme.spacing(2, 0, 2, 0),
      fontSize:15,
      fontWeight:600,
      color:'white',
      [theme.breakpoints.down('sm')]: {
        fontSize:10,
      },
  }
}));

const ButtonComponent = ({ children, classname ,fullWidth, disabled, ...rest }) => {
  const classes = useStyles();
  return (
    <div>
      <Button
        className={classname?clsx(classes.button,classname):classes.button}
        disabled={disabled || false}
        //   variant={variant}
        //   color={color}
        fullWidth={false || fullWidth}
        //   style={style}
        //   id={id}
        //   onClick={onClick}
        {...rest}
      >
        {children}
      </Button>
    </div>
  );
};

export default ButtonComponent;

// const Button = ({ text, classes }) => {
//   return <button className={classes}>{text}</button>;
// };

// export default Button;
