import React from "react"
import {useHistory} from 'react-router-dom'
var jwt = require('jsonwebtoken');


const CurrentUserID = () => {
  const data = localStorage.getItem('Auth-Token');
  try {
      return jwt.verify(data,'abcd1234');
  }
  catch(ex){
      return null
  }
}



const ProtectedRoute = ({ component: Component, location, path, ...rest }) => {
  const history=useHistory()

  if(CurrentUserID() && path==='/login'){
    history.push('/')
    // return null
  }
  if(!CurrentUserID() && path!=='/login'){
    history.push('/login')
  }
  // else{
  //   // history.push('/login')
  // }
  return <Component  {...rest} />
}

export default ProtectedRoute