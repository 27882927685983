import React,{useState,useEffect} from 'react';
import {Typography,makeStyles,Grid} from '@material-ui/core'
import CardWithIcon from '../../components/cardWithIcon/CardWithIcon'
import clsx from 'clsx'
import red from '@material-ui/core/colors/red';
import axios from 'axios'
import htmlToImage from 'html-to-image';
import download from 'downloadjs'
import { saveAs } from 'file-saver'
import domtoimage from 'dom-to-image';
import jsPDF from 'jspdf'
import html2canvas from 'html2canvas';



import TopLayer from '../../components/layout/layout'
import Layout2 from '../../components/layoutsForDashboard/Layout2'
import Dropdown from '../../components/dropdown/Dropdown'
import BarCharts from '../../components/Charts/BarChart'

import {RiSurveyLine} from 'react-icons/ri'
import {GoGraph} from 'react-icons/go'
import {FaRegCalendarTimes,FaRegCalendarCheck,FaRegCalendarAlt} from 'react-icons/fa'
import {AiOutlineFileDone,AiOutlinePercentage} from 'react-icons/ai'
import {GrCompliance} from 'react-icons/gr'
import {MdContentCopy} from 'react-icons/md'
import {GiPerson,} from 'react-icons/gi'
import {BsFillPersonFill} from 'react-icons/bs'
import Warning from "@material-ui/icons/Warning";

const useStyles = makeStyles((theme) => ({
   container:{
       borderRadius:theme.spacing(2),
       border:"1px solid lightgrey",
       padding:theme.spacing(0,0,0,0),
       margin:theme.spacing(0,0,10,0)
   },
   heading:{
       padding:theme.spacing(2,3,2,3),
       borderTopRightRadius:theme.spacing(2),
       borderTopLeftRadius:theme.spacing(2),
       marginBottom:theme.spacing(3),
       color:'white' 
   },
   dropdownContainer:{
       padding:theme.spacing(3),
       width:'20%'
   },
   dropdownContainer1:{
       padding:theme.spacing(3),
       width:'40%'
   },
   grid:{
       padding:theme.spacing(3)
   },
   paddingL:{
    //    marginLeft:theme.spacing(2)
   },
   paddingR:{
    //   marginRight:theme.spacing(2)
   }
}));
 

const ClientDashboard = () => {
    const classes=useStyles()
    const [brand,setBrand]=useState('All')
    const [city,setCity]=useState(null)
    const [filteredBrand,setFilteredBrand]=useState(null)
    const [bannerValues,setBannerValues]=useState(null)
    const [brandBannerValues,setBrandBannerValues]=useState(null)
    const [citiesList,setCitiesList]=useState([])
    const [brandListAll,setBrandListAll]=useState([])
    const [brandList,setBrandList]=useState([])
    const [graphValues,setGraphValues]=useState(null)
    const [locationWiseGraph,setLocationWiseGraph]=useState(null)
    const [filteredWiseGraph,setFilteredWiseGraph]=useState(null)
    // const [brandOptions,setBrandOptions]=useState(null)
    const [init,setInit]=useState(false)
    const primary = red[500]; // #f44336


    useEffect(()=>{
        if(!init){
            getBannerValues()
            getCompetitionBrands()
            getBannerValuesByBrand('All')
            getCityWisePurchaseGraph()
            getCities()
            setInit(true)
        }
    },[init])

    const getCities=async()=>{
        const res=await axios.get('http://espyv2.markematics.net/EspyV2.svc/getCities')
        if(res){
            // console.log(JSON.parse(res.data).Cities)
            setCitiesList(JSON.parse(res.data).Cities)
            // setBannerValues(JSON.parse(res.data).BannerValues[0])
        }
    }

    const getBannerValuesByBrand=async(brand)=>{
        const res=await axios.get(`http://espyv2.markematics.net/EspyV2.svc/getBannerValuesByBrand?brand=${brand}`)
        if(res){
            setBrandBannerValues(JSON.parse(res.data).CompetitionBannerValues[0])
        }
    }

    const getLocaionWiseValuesByCity=async(city)=>{
        const res=await axios.get(`http://espyv2.markematics.net/EspyV2.svc/getLocaionWiseValuesByCity?city=${city}`)
        if(res){
            let labels=[]
            let values=[]
            JSON.parse(res.data).StoreLocationValues.map(item=>{
                labels.push(item.location)
                values.push(item.OverAll)
            })
            let data={
                labels,
                series:[values]
            }
            setLocationWiseGraph(data)
        }
    }

    const getCityWisePurchaseGraph=async()=>{
        const res=await axios.get('http://espyv2.markematics.net/EspyV2.svc/getCityWisePurchaseGraph')
        if(res){
            let labels=[]
            let values=[]
            JSON.parse(res.data).CityWisePurchaseValues.map(item=>{
                labels.push(item.city)
                values.push(item.OverAll)
            })
            let data={
                labels,
                series:[values]
            }
            setGraphValues(data)
        }
    }

    const getCompetitionBrands=async()=>{
        const res=await axios.get('http://espyv2.markematics.net/EspyV2.svc/getCompitionBrand')
        if(res){
            let temp=['All',...JSON.parse(res.data).CompetitionBrands]
            setBrandListAll(temp)
            setBrandList([...JSON.parse(res.data).CompetitionBrands])
        }
    }

    const getBannerValues=async()=>{
        const res=await axios.get('http://espyv2.markematics.net/EspyV2.svc/getBannerValues')
        if(res){
            setBannerValues(JSON.parse(res.data).BannerValues[0])
        }
    }

    const filteredGraphByBrand=async(brand)=>{
        const res=await axios.get(`http://espyv2.markematics.net/EspyV2.svc/getCityWiseValuesByBrand?brand=${brand}`)
        if(res){
            let labels=[]
            let values=[]
            JSON.parse(res.data).CompetitionCityValues.map(item=>{
                labels.push(item.city)
                values.push(item.OverAll)
            })
            let data={
                labels,
                series:[values]
            }
            setFilteredWiseGraph(data)
        }
    }

    const cities=[
        'Karachi',
        'Lahore',
        'Islamabad'
    ]

    const brandOptions=[
        'A',
        'B',
        'C'
    ]

    const filteredBrandOptions=[
        'A1',
        'B1',
        'C1'
    ]

    const onChange=(event)=>{
        let {value}=event.target
        getBannerValuesByBrand(value)
        setBrand(value)
    }

    const onChange1=(event)=>{
        let {value}=event.target
        getLocaionWiseValuesByCity(value)
        setCity(value)
    }

    const onChange2=(event)=>{
        let {value}=event.target
        filteredGraphByBrand(value)
        setFilteredBrand(value)
    }

    const saveGraph=()=>{
        
            // html2canvas(document.getElementById('abc')).then(img=>{
            //     console.log(img.toDataURL("image/png"))
            //     window.saveAs(img.toDataURL("image/png"),'img.png')
            //     // domtoimage.toBlob(img.toDataURL())
            //     // .then(function (blob) {
            //     //     window.saveAs(blob,'chart.png')
            //     // });
            // })
        
        domtoimage.toBlob(document.getElementById('chart'))
        .then(function (blob) {
            window.saveAs(blob,'chart.png')
        })
        .catch(err=>{
            console.log(err)
        })

    }

    return (
        <TopLayer>
            <div id="abc" className={classes.container}>
                <Typography style={{background:'linear-gradient(60deg, #EF5350, #E53935)'}} className={classes.heading} variant="h5">Bonanza Outlets</Typography>
                {
                    bannerValues && 
                    <Layout2
                        margin={false}
                        R2C1={<CardWithIcon iconColor={'success'} Icon={FaRegCalendarAlt} title={'Total Visit'} caption={bannerValues.TotalVists} />}
                        R2C2={<CardWithIcon iconColor={'rose'} Icon={FaRegCalendarCheck} title={'Visits Completed'} caption={bannerValues.CompletedVisits} />}
                        R2C3={<CardWithIcon Icon={FaRegCalendarTimes} title={'Visits Incompleted'} caption={bannerValues.VisitsInCompeleted} />}
                        R2C4={<CardWithIcon iconColor={'info'} Icon={GoGraph} title={'Over All Score'} caption={bannerValues.TotalScore} />}
                    />
                }
                
            </div>
            {/* <div className={classes.container}>
                <Typography style={{background:'linear-gradient(60deg, #FFA726, #FB8C00)'}} className={classes.heading} variant="h5">Competition Outlets</Typography>
                
                <div className={classes.dropdownContainer}>
                    <Dropdown id="brand"   name="brand" options={brandListAll} selected={brand} placeholder="Brand" label="Brand" onChange={onChange}/>
                </div>
                {
                    brandBannerValues && 
                    <Layout2
                        margin={false}
                        R2C1={<CardWithIcon iconColor={'success'} Icon={FaRegCalendarAlt} title={'Total Visit'} caption={brandBannerValues.TotalVistsCompetition} />}
                        R2C2={<CardWithIcon iconColor={'rose'} Icon={FaRegCalendarCheck} title={'Visits Completed'} caption={brandBannerValues.CompletedVistCompetition} />}
                        R2C3={<CardWithIcon Icon={FaRegCalendarTimes} title={'Visits Incompleted'} caption={brandBannerValues.VisitsInCompeletedCompetition} />}
                        R2C4={<CardWithIcon iconColor={'info'} Icon={GoGraph} title={'Over All Score'} caption={brandBannerValues.TotalScore} />}
                    />
                }
                
            </div> */}

            {/* <button onClick={saveGraph}>Save</button> */}
            <div id="chart" className={classes.container}>
                <Typography style={{background:'linear-gradient(60deg, #66BB6A, #43A047)'}} className={classes.heading} variant="h5">Over All Graph Purchase</Typography>
                <div style={{height:'150%',width:'100%'}}>
                    {graphValues &&<BarCharts data={graphValues}/>}
                </div>
            </div>

            <div className={classes.container}>
                <Typography style={{width:'100%',background:'linear-gradient(60deg, #26C6DA, #00ACC1)'}} className={classes.heading} variant="h5">City Wise Score Over All Average</Typography>
                <div style={{width:'100%'}}>
                    <div className={classes.dropdownContainer}>
                        <Dropdown id="city"  name="city"  options={citiesList} selected={city} placeholder="City" label="City" onChange={onChange1}/>
                    </div>
                    <div style={{height:'100%',width:'100%'}}>
                        {
                            locationWiseGraph &&
                            <BarCharts data={locationWiseGraph}/>
                        } 
                    </div>
                </div>
            </div>

            {/* <div className={classes.container}>
                <Typography style={{width:'100%',background:'linear-gradient(60deg, #AB47BC, #8E24AA)'}} className={classes.heading} variant="h5">Filtered Graph<br/></Typography>
                <div style={{width:'100%'}}>  
                    <div className={classes.dropdownContainer}>
                        <Dropdown id="filteredBrand"  name="filteredBrand" options={brandList} selected={filteredBrand} placeholder="Brand" label="Brand" onChange={onChange2}/>
                    </div>
                    <div style={{height:'100%',width:'100%'}}>
                        {
                            filteredWiseGraph && 
                            <BarCharts data={filteredWiseGraph}/>
                        }
                    </div>
                </div>
            </div> */}

 
            {/* <Grid container justify="space-evenly">
                <Grid container xs={12} sm={5} className={clsx(classes.container,classes.paddingR)}>
                    <Typography style={{width:'100%',background:'linear-gradient(60deg, #26C6DA, #00ACC1)'}} className={classes.heading} variant="h5">City Wise Score Over All Average</Typography>
                    <div style={{width:'100%'}}>
                        <div className={classes.dropdownContainer1}>
                            <Dropdown id="city"  name="city"  options={citiesList} selected={city} placeholder="City" label="City" onChange={onChange1}/>
                        </div>
                        <div style={{height:'100%',width:'100%'}}>
                            {
                                locationWiseGraph &&
                                <BarCharts data={locationWiseGraph}/>
                            } 
                        </div>
                    </div>
                </Grid>      
                <Grid container xs={12} sm={5} className={clsx(classes.container,classes.paddingL)}>
                    <Typography style={{width:'100%',background:'linear-gradient(60deg, #AB47BC, #8E24AA)'}} className={classes.heading} variant="h5">Filtered Graph<br/></Typography>
                    <div style={{width:'100%'}}>  
                        <div className={classes.dropdownContainer1}>
                            <Dropdown id="filteredBrand"  name="filteredBrand" options={brandList} selected={filteredBrand} placeholder="Brand" label="Brand" onChange={(event)=>setFilteredBrand(event.target.value)}/>
                        </div>
                        <div style={{height:'100%',width:'100%'}}>
                            <BarCharts />
                        </div>
                    </div>
                </Grid>      
            </Grid>        */}
        </TopLayer>
    );
}
export default ClientDashboard;