import React,{useState,useEffect} from 'react';
import {Grid,Typography,makeStyles,Paper} from '@material-ui/core'
import Joi from 'joi-browser'
import {useHistory} from 'react-router-dom'
import Loader from '../../components/loader/loader'
import Error from '../../components/error/error'
import axios from 'axios'


import ButtonComponent from '../../components/button/Button'
import Input from '../../components/input/Input'

var jwt = require('jsonwebtoken');

const useStyles = makeStyles(theme => ({
    div:{
        height:'99.7vh',
    },
    width:{
        minHeight:'300px',
        width:'30%',
        padding:theme.spacing(10)
    }
}));



const Login = () => {
    const classes=useStyles()
    const [data,setData]=useState({})
    const [loading,setLoading]=useState(false)
    const [error, setError] = useState({});
    const history=useHistory()

    useEffect(()=>{
        if(!error){
            onSubmit()
        }
    },[error])

    const schema = {
        username: Joi.string().required(),
        password: Joi.string().required()
    }
    
    const validate = () => {
		const result = Joi.validate(data, schema, {abortEarly:false});
	
		if(!result.error) return null;
	
		const newError = {};
		result.error.details.map(err => {
			return newError[err.path[0]] = err.message;
        });

		return newError;   
    }

    const handleClick = () => {
		setError(validate());
    }

    const onKeyDown=(event)=>{
        if(event.key==='Enter'){
          handleClick()
        }
      } 

    const onChange=(event)=>{
        let {id,value}=event.target
        let temp={...data}
        temp[id]=value
        setData(temp)
    }

    const onSubmit=async()=>{
        setLoading(true)
        const res=await axios.post('http://espyv2.markematics.net/EspyV2.svc/loginWeb',{userName:data.username,password:data.password})
        console.log(JSON.parse(JSON.parse(res.data).data))
        if(JSON.parse(res.data).data!==null){
            const token = jwt.sign(JSON.parse(JSON.parse(res.data).data), 'abcd1234');
            if(token){
                localStorage.setItem('Auth-Token', token);
                history.push('/')
            }

        }
        else{
            setLoading(false)
            setError({username:"Invalid Username or Password",password:"Invalid Username or Password"})
        }
        
        // setTimeout(()=>{
        //     console.log(data)
        // //     const user=await IndividualSignin(data)
        // //    if(user){
        // //        window.open(`/${user.role}`,'_self')
        // //    }
        // //    else if(!user){
        // //      setLoading(false)
        // //      setError({email:"Invalid Email or Password",password:"Invalid Email or Password"})
        // //    }
        //     
        // },10000)
        
    }


    return (
        <Grid container className={classes.div} justify="center" alignItems="center">
            <Paper className={classes.width}>
                {
                    loading?
                    <Loader color="#2196f3"/>
                    :
                    <>
                        <Typography variant="h3"  gutterBottom><b>Login</b></Typography>

                        <Input variant="outlined" fullWidth id="username" onKeyDown={onKeyDown} placeholder="Username" label="Username" onChange={onChange}/>
                        {error && error.username && <Error text={error.username} /> }
                        
                        <Input variant="outlined" fullWidth id="password" onKeyDown={onKeyDown} type="password" placeholder="Password" label="Password" onChange={onChange}/>
                        {error && error.password && <Error text={error.password} /> }

                        <ButtonComponent color="primary" variant="contained" onClick={handleClick}>Login</ButtonComponent>
                    </>
                }
            </Paper>
        </Grid>
    );
}

export default Login;